import React from 'react';
import { Container, Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Content from './Content.json';
import PrimaryTitle from './PrimaryTitle';
import 'owl.carousel/dist/assets/owl.carousel.css';
import { useTranslation } from 'react-i18next';
import OwlCarouselWrapper from './OwlCarouselWrapper';

const useStyles = makeStyles(theme => ({
    root: { flexGrow: 1, marginBottom: 50 },
    howItWorks: {
        position: 'relative',
        '&:after': {
            content: `" "`,
            position: 'absolute',
            left: '10%',
            top: '35%',
            width: '75%',
            height: 2,
            background: '#eee',
            zIndex: 0
        }
    },
    howDcWorksMobile: {
        '& img': {
            maxWidth: '150px',
            width: '100%'
        }
    },
    subTitle: { fontWeight: 300, fontSize: 12, textAlign: 'center' },
    text: {
        marginTop: theme.spacing(1.25),
        textAlign: 'center',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '1.3',
        color: '#5b5b5b'
    },
    iconMd: {
        maxWidth: '100%',
        height: 90,
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down(767)]: {
            width: 'auto',
            maxWidth: '200px'
        }
    },
    itemIcon: {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1
    }
}));

const options = {
    items: 2,
    nav: true,
    loop: true,
    autoplay: true,
    margin: 0,
    lazyLoad: true,
    responsive: {
        0: {
            items: 1
        },
        400: {
            items: 1
        },
        600: {
            items: 2
        },
        800: {
            items: 4
        },
        1000: {
            items: 4
        }
    }
};

export default function HowDcWorks(title) {
    const { t, i18n } = useTranslation('common');
    const creditData = Content.creditContent.steps;
    const classes = useStyles();
    return (
        <div className="wrapper">
            <Container fixed className={classes.root}>
                <PrimaryTitle name={t('How Droom Credit Works', 'How Droom Credit Works')}></PrimaryTitle>
                <Hidden smDown>
                    <Grid container spacing={3} className={classes.howItWorks}>
                        {creditData.map((item, index) => (
                            <Grid item xs={3} key={index}>
                                <ListItemIcon className={classes.itemIcon}>
                                    <img
                                        className={classes.iconMd}
                                        src={item.img}
                                        alt={item.altTxt}
                                    />
                                </ListItemIcon>
                                <Typography
                                    className={classes.text}
                                    dangerouslySetInnerHTML={{ __html: `${t(item.translationKey1.key, item.translationKey1.fallback)} <br\/> ${t(item.translationKey2.key, item.translationKey2.fallback)}` }}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </Hidden>
                <Hidden smUp>
                    <OwlCarouselWrapper {...options} className="howDcWorksMobile">
                        {creditData.map((item, index) => (
                            <div key={index}>
                                <ListItemIcon className={classes.itemIcon}>
                                    <img
                                        className={classes.iconMd}
                                        src={item.img}
                                        alt={item.altTxt}
                                    />
                                </ListItemIcon>
                                <Typography variant="body2" className={classes.subTitle}>
                                    {t('Step')} {index + 1}
                                </Typography>
                                <Typography
                                    className={classes.text}
                                    dangerouslySetInnerHTML={{ __html: t(item.translationKey1.key, item.translationKey1.fallback) + " <br\/> " + t(item.translationKey2.key, item.translationKey2.fallback) }}
                                />
                            </div>
                        ))}
                    </OwlCarouselWrapper>
                </Hidden>
            </Container>
        </div>
    );
}

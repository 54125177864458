import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Radios } from 'mui-rff';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme =>
    createStyles({
        icon: {
            display: 'inline-block',
            borderRadius: '50%',
            width: 16,
            height: 16,
            boxShadow:
                'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
            backgroundColor: '#f5f8fa',
            backgroundImage:
                'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
            '$root.Mui-focusVisible &': {
                outline: '2px auto rgba(19,124,189,.6)',
                outlineOffset: 2
            },
            'input:hover ~ &': {
                backgroundColor: '#ebf1f5'
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: 'rgba(206,217,224,.5)'
            }
        },
        checkedIcon: {
            backgroundColor: '#a3be28',
            backgroundImage:
                'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
            '&:before': {
                display: 'block',
                width: 16,
                height: 16,
                backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
                content: `""`
            },
            'input:hover ~ &': {
                backgroundColor: '#a3be28'
            }
        }
    })
);

export default function RadioButtons(props) {
    const { t, i18n } = useTranslation(['app', 'common']);
    const classes = useStyles();
    return (
        <Radios
            label={t('Looking For Which Vehicle?', 'Looking For Which Vehicle?')}
            name="vehicle"
            required={props.required.vehicle}

            //Material Form Label Props
            formLabelProps={{
                component: "legend",
                style: { fontSize: 12, fontWeight: 600, marginTop: 10, marginBottom: 5 }
            }}

            //Material Radio Group Props
            radioGroupProps={{
                row: true,
                "aria-label": "position"
            }}

            //Material Radio Props
            className={classes.root}
            color='default'
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}

            //Data for each radio buttons
            data={[
                { label: t('common:Car', 'Car'), value: "car" },
                { label: t('common:Bike', 'Bike'), value: "motorcycle/bike" },
                { label: t('common:Scooter', 'Scooter'), value: "scooter" }
            ]}
        />
    );
}

RadioButtons.propTypes = {
    required: PropTypes.object.isRequired
}





import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails
} from './AccordionMuiTheme';
import { Link } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import PrimaryTitles from './PrimaryTitle';
import { useTranslation } from 'react-i18next';
import { getLocalizedPath } from '../../../i18n/functions';

const useStyles = makeStyles(theme => ({
    aboutMainDiv: {
        padding: theme.spacing(3),
        minHeight: 280,
        background:
            'url(https://cdn1.acedms.com/photos/images/droomCredit/v2/about-bg.png) center top',
        '& .Credit-MuiAccordionSummary-expandIcon.Credit-Mui-expanded': {
            transform: 'rotate(90deg)',
            top: 2
        }
    }
}));

const fallBackTranlations = {
    "home-h1-p1": "A personal vehicle makes every day commuting much more convenient in a country where the infrastructure of public transport is crumbling. But not everyone has the hard cash to pay for the full amount of two wheeler vehicle or four wheeler vehicle. This is where an auto loan or a vehicle loan helps an individual to own a vehicle just paying a small amount of money every month. A vehicle loan or an auto loan is financial lending by the banks and NBFC companies to individuals who in turn repay the entire loan amount in parts known as EMI (Equated Monthly Instalment). The buyer pays the part of the total price as a down payment while the rest of the payment if financed by the lender.",
    "home-h2-p1": "There are several benefits of an auto loan as you may get 100% vehicle finance on ex-showroom price. This obviously reduces your waiting phase to own your dream two wheeler or four wheeler. Also, Droom offers flexibility of choosing the loan tenure as per your comfort. There is an option for vehicle finance for a maximum of seven years. We also give vehicle loan for used vehicles at the lowest possible rate of interest.If you have a good Credit Score",
    "home-h2-p2": ", then you can even negotiate on the rate of interest. The best part of the vehicle loan is that, no collateral is required to avail the auto loan.",
    "home-h3-p1": "One of the most crucial factors that you should consider is the rate of interest. The rate of interest is decided on the basis of your credit score, loan principal, tenure and most importantly vehicle type. We offer the lowest vehicle loan interest rate. The auto loan interest rate for new two-wheelers starts at just 6.99% per annum while it is 8.99% per annum for used two wheeler.",
    "home-h3-p2": "The loan for four wheeler vehicle starts at just 9.99% per annum for new vehicles while it is 11.99% per annum for used vehicles. The maximum tenure for the vehicle loan is 7 years which is quite comfortable for an average salaried individual. With a meagre loan processing fee, you can bring home your dream vehicle.",
    "home-h4-p1": "It is very important to know how much an auto loan will affect your monthly budget. An auto loan calculator helps you to know what interest rate and tenure of the auto loan will cost you. The",
    "home-h4-p2": "has three sliders which can be adjusted as per your auto loan conditions. To use the EMI calculator, enter the price of the vehicle that you wish to buy, then adjust the sliders of down payment, repayment tenure and interest rate as per your auto loan requirements. Then click on the calculate your EMI tab which would then display the exact EMI you have to pay. It is one of the simplest vehicle loan calculator present online."
}

export default function AboutLoans() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = panel => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const classes = useStyles();
    const { t, i18n } = useTranslation(['app', 'common']);
    return (
        <div className={classes.aboutMainDiv}>
            <div className="container">
                <PrimaryTitles name={t('common:About Vehicle Loans', 'About Vehicle Loans')}></PrimaryTitles>
                <Accordion
                    expanded={expanded === 'panel1'}
                    onChange={handleChange('panel1')}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                    >
                        <Typography component="h3" variant="subtitle1">
                            {t('home-h1', 'Vehicle Loan')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2">
                            {t('home-h1-p1', fallBackTranlations['home-h1-p1'])}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panel2'}
                    onChange={handleChange('panel2')}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                    >
                        <Typography component="h3" variant="subtitle1">
                            {t('home-h2', 'Benefits Of Vehicle Loan')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2">
                            {t('home-h2-p1', fallBackTranlations['home-h2-p1'])}
                            <Link
                                color="primary"
                                href={getLocalizedPath('check-credit-score-free', i18n)}
                            >
                                {` ${t('common:Credit Score', 'Credit Score')}`}
                            </Link>
                            {t('home-h2-p2', fallBackTranlations['home-h2-p2'])}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panel3'}
                    onChange={handleChange('panel3')}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                    >
                        <Typography component="h3" variant="subtitle1">
                            {t('home-h3', 'Vehicle Loan Interest Rate')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2">
                            {t('home-h3-p1', fallBackTranlations['home-h3-p1'])}
                        </Typography>
                    </AccordionDetails>
                    <AccordionDetails>
                        <Typography variant="body2">
                            {t('home-h3-p2', fallBackTranlations['home-h3-p2'])}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion
                    expanded={expanded === 'panel4'}
                    onChange={handleChange('panel4')}
                    className={classes.mainPanel}
                >
                    <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography component="h3" variant="subtitle1">
                            {t('home-h4', 'Vehicle Loan Calculator')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="body2">
                            {t('home-h4-p1', fallBackTranlations['home-h4-p1'])}
                            <Link
                                color="primary"
                                href={getLocalizedPath('emi-calculator', i18n)}
                            >
                                {` ${t('common:EMI Calculator', 'EMI Calculator')} `}
                            </Link>
                            {t('home-h4-p2', fallBackTranlations['home-h4-p2'])}
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </div>
        </div>
    );
}

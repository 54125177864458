import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

export const Accordion = withStyles({
    root: {
        border: 0,
        boxShadow: 'none',
        paddingLeft: 0,
        backgroundColor: 'transparent',
        '&:not(:last-child)': {
            borderBottom: 0
        },
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            margin: 'auto'
        }
    },
    expanded: {}
})(MuiAccordion);

export const AccordionSummary = withStyles({
    root: {
        position: 'relative',
        border: 0,
        padding: '0 30px',
        marginBottom: '-1px',
        minHeight: 30,
        '&$expanded': {
            minHeight: 30
        }
    },
    expandIcon: {
        position: 'absolute',
        left: -5,
        top: 10,
        padding: 0
    },
    content: {
        '&$expanded': {
            margin: '5px 0',
            border: 0
        }
    },
    expanded: {}
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(theme => ({
    root: {
        border: 0,
        padding: theme.spacing(0, 4)
    }
}))(MuiAccordionDetails);

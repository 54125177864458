import React from 'react';
import {
    List,
    ListItem,
    ListItemText,
    makeStyles,
    Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    productDetailContainer: {
        flexGrow: 1
    },
    pdIcons: {
        marginRight: theme.spacing(1.5),
        verticalAlign: 'middle'
    },
    pdText: {
        display: 'inline-block',
        verticalAlign: 'middle',
        color: '#fff',
        [theme.breakpoints.down('xs')]: {
            fontSize: theme.spacing(1.75)
        }
    },
    listItemBody: {
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0.5, 0)
        }
    }
}));

const productDetailList = [
    {
        listIcon: 'https://cdn2.droom.in/imgusr/droomCredit/v2/icon1.png',
        listIconText: 'Loan Decision In 30 Seconds',
        listText: 'Loan Decision In 30 Seconds',
        translationKey: 'Loan Decision in 30 Seconds'
    },
    {
        listIcon: 'https://cdn2.droom.in/imgusr/droomCredit/v2/icon2.png',
        listIconText: 'Hassle Free & Paperless',
        listText: 'Hassle Free & Paperless',
        translationKey: 'Hassle free & Paperless',

    },
    {
        listIcon: 'https://cdn2.droom.in/imgusr/droomCredit/v2/icon3.png',
        listIconText: 'Unbeatable Loan Terms',
        listText: 'Unbeatable Loan Terms',
        translationKey: 'Unbeatable Loan Terms'
    },
    {
        listIcon: 'https://cdn2.droom.in/imgusr/droomCredit/v2/icon4.png',
        listIconText: 'Real Time Quotes From Multiple Lenders',
        listText: 'Real Time Quotes From Multiple Lenders',
        translationKey: 'Real time quotes from multiple lenders'
    }
];
export default function ProductDetails() {
    const { t, i18n } = useTranslation('app');
    const classes = useStyles();
    return (
        <div className={classes.productDetailContainer}>
            <List>
                {productDetailList.map((lsItem, index) => (
                    <ListItem className={classes.listItemBody} key={index}>
                        <ListItemText style={{ color: 'white' }}>
                            <img
                                src={lsItem.listIcon}
                                alt={lsItem.listIconText}
                                className={classes.pdIcons}
                            />
                            <Typography className={classes.pdText}>
                                {t(lsItem.translationKey, lsItem.listText)}
                            </Typography>
                        </ListItemText>
                    </ListItem>
                ))}
            </List>
        </div>
    );
}

import React from 'react';
import { isNode } from '../../../config';
import { PageContainer, ClientHydrator } from '../../components';
import { MainBanner } from './partials';
import { useSelector } from 'react-redux';

const HydrateProgress = [
    "Benefits",
    "HowDcWorks",
    "Partners",
    "Testimonials",
    "AboutLoans",
    "Faqs"
];

const HomePageContent = () => {
    const isLoggedIn = useSelector(state => state.user.isLoggedIn);
    return (
        <>
            {
                HydrateProgress.map((component, index) => {
                    let loadComponent = null;
                    if (isNode) {
                        loadComponent = require(`./partials/${component}`).default;
                        return <loadComponent key={index} />
                    } else {
                        loadComponent = () => import(`./partials/${component}`);
                        if (component == "Benefits") {
                            return <ClientHydrator key={index} load={loadComponent} isLoggedIn={isLoggedIn} />
                        } else {
                            return <ClientHydrator key={index} load={loadComponent} />
                        }
                    }
                })
            }
        </>
    );
}

const Home = () => {
    return (
        <PageContainer title="Home">
            <MainBanner />
            {
                HomePageContent()
            }
        </PageContainer>
    );
}

export default React.memo(Home);

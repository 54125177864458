import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PrimaryTitle from './PrimaryTitle';
import { CardContent, CardMedia, Divider, Typography } from '@material-ui/core';
import 'owl.carousel/dist/assets/owl.carousel.css';
import './CustomCarousel.css';
import { useTranslation } from 'react-i18next';
import OwlCarouselWrapper from './OwlCarouselWrapper';

const useStyles = makeStyles(theme => ({
    header: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        '&:before': {
            content: ` url('https://cdn1.acedms.com/photos/images/droomCredit/v2/quote-1.png') `,
            position: 'absolute',
            left: 10,
            top: 10
        },
        [theme.breakpoints.down(767)]: {
            '&:before': {
                display: 'none'
            }
        }
    },
    cover: {
        width: theme.spacing(4.2),
        height: theme.spacing(4.8)
    },
    content: {
        display: 'flex',
        flexDirection: 'column'
    },
    titleText: {
        fontWeight: 600,
        textTransform: 'uppercase'
    },
    centerText: {
        marginBottom: theme.spacing(2),
        textAlign: 'center'
    },
    avatar: {
        display: 'flex',
        marginTop: theme.spacing(2),
        alignItems: 'center',
        justifyContent: 'center'
    },
    innerSection: {
        position: 'relative',
        background: '#fff',
        padding: theme.spacing(3),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down(767)]: {
            padding: theme.spacing(2.5),
            '&:before': {
                content: ` url('https://cdn1.acedms.com/photos/images/droomCredit/v2/quote-1.png') `,
                position: 'absolute',
                left: 10,
                top: -40
            }
        }
    }
}));
const options = {
    items: 1,
    nav: false,
    loop: true,
    autoplay: false,
    lazyLoad: true,
    margin: 10,
    dots: true
};

export default function Testimonials() {
    const { t, i18n } = useTranslation('app');
    const classes = useStyles();
    return (
        <div className="wrapper">
            <div className="container">
                <PrimaryTitle name={t('testimonial-heading', 'Testimonials')}></PrimaryTitle>
                <div className={classes.innerSection}>
                    <OwlCarouselWrapper className="owl-theme" {...options}>
                        <div>
                            <div className={classes.header}>
                                <CardMedia
                                    image="https://cdn1.acedms.com/photos/images/droomCredit/v2/gurugram-police.png"
                                    className={classes.cover}
                                />
                                <CardContent className={classes.content}>
                                    <Typography
                                        variant="h6"
                                        component="h4"
                                        className={classes.titleText}
                                    >
                                        {t('Gurugram Police', 'Gurugram Police')}
                                    </Typography>
                                </CardContent>
                            </div>
                            <CardContent className={classes.centerText}>
                                <Typography variant="body2">
                                    {t('testimonial-p1', 'We are grateful to Droom for launching this service and protecting us against the highly - contagious Coronavirus.It is our duty to ensure the health and safety of citizens and staying protected from the virus ourselves is a big part of protecting others.A major element of containing the Coronavirus contagion is inhibiting surface - to - surface transmission, and Droom’s Germ-Shield technology is a big step towards this goal.')}
                                </Typography>
                            </CardContent>
                            <Divider />
                            <div className={classes.avatar}>
                                <CardMedia
                                    component="img"
                                    alt="Nitika Gahlaut"
                                    height="140"
                                    image="https://cdn1.acedms.com/photos/images/droomCredit/v2/nitika-gahlaut.png"
                                    title="Contemplative Reptile"
                                    className="avatarImg"
                                />
                                <CardContent className="avatarDetails">
                                    <Typography variant="caption" display="block" gutterBottom>
                                        {t('Mrs Nitika Gahlaut', 'Mrs. Nitika Gahlaut')}
                                    </Typography>
                                    <Typography variant="caption" display="block">
                                        {t('IPS DCP Headquarters, Gurugram', 'IPS DCP Headquarters, Gurugram')}
                                    </Typography>
                                </CardContent>
                            </div>
                        </div>
                    </OwlCarouselWrapper>
                </div>
            </div>
        </div>
    );
}

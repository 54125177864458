import React from 'react';
import { isNode } from '../../../../config';

const OwlCarouselWrapper = (props) => {
    const { children, ...rest } = props;
    if (isNode) {
        return null;
    } else {
        const OwlCarousel = require('react-owl-carousel');
        return (
            <OwlCarousel {...rest}>
                {
                    children
                }
            </OwlCarousel>
        );
    }
}

export default OwlCarouselWrapper;